export function userReducer(
  state = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  action
) {
  switch (action.type) {
    case "LOGIN":
      return action.payload;

    case "LOGOUT":
      return null;

    default:
      return state;
  }
}
